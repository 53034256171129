<template>
    <div class='app-container'>
        <!-- 表格 -->
        <div>
            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                    <el-button type="success" size="small" @click="insertBatchHandle">创建批次</el-button>
                </template>
            </vxe-toolbar>
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" class="mytable-scrollbar batchTable" round align="left"
                ref="paperTable" highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}"
                :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading'
                :data="batchList">
                <vxe-column type='checkbox' width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='seq' title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="batchName" title="批次名称" width="300" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="sendingRuleName" title="管理规则" width="300" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="createTime" title="创建时间" width="150" :show-overflow="'tooltip'"></vxe-column>
                <!-- <vxe-column field="batchName" title="作者" width="100" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="endTime" title="截至时间" width="120" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column title="状态" width="100">
                    <template #default="{ row }">
                        已上传
                    </template>
                </vxe-column> -->
                <vxe-column title="操作" min-width="120">
                    <template #default="{ row }">
                        <el-button type="text" size="mini" @click="enterBatchHandle(row.batchId,row.batchName)">进入批次
                        </el-button>
                    </template>
                </vxe-column>
            </vxe-table>
            <!-- <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="page.pageSizes"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager> -->
            <vxe-modal v-model="insertModel" :position="{top:'0px'}" width="500" :show-footer='true' show-zoom resize
                :transfer='true'>
                <template #title>
                    <span>创建批次</span>
                </template>
                <vxe-form :data="formData" title-align="right" title-width="100">
                    <vxe-form-item field="batchName" title="批次名称:" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-input v-model="data.batchName" placeholder="请输入" size="medium"> </vxe-input>
                        </template>
                    </vxe-form-item>
                    <vxe-form-item field="returnTime" title="返回时间:" :span="22" :item-render="{}">
                      <template #default="{ data }">
                        <el-date-picker v-model="data.returnTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择日期" size="medium"></el-date-picker>
                      </template>
                    </vxe-form-item>
                    <!-- <vxe-form-item field="sendingRuleId" title="管理规则:" :span="22" :item-render="{}">
                        <template #default="{ data }">
                            <vxe-select v-model="data.sendingRuleId" placeholder="请选择" size="medium" transfer clearable>
                                <vxe-option v-for="item in sendingRuleList" :key="item.sendingRuleId" :value="item.sendingRuleId" :label="item.sendingRuleName"></vxe-option>
                            </vxe-select>
                        </template>
                    </vxe-form-item> -->
                </vxe-form>
                <template #footer>
                    <el-button type="primary" size="small" @click="postBatchCrate">确定</el-button>
                </template>
            </vxe-modal>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'batchManage',
        data() {
            return {
                insertModel: false,
                batchList: [],
                formData: {
                    batchName: '',
                    sendingRuleId: '',
                    returnTime: ''
                },
                sendingRuleList: []
            }
        },
        created() {
            this.getSendingRuleList();
            this.getAllBatch();
        },
        methods: {
            async postBatchCrate() {
                await this.getSendingRuleList();
                let params = {
                    sendingRuleId: this.sendingRuleList.length == 0 ? 0 : this.sendingRuleList[0].sendingRuleId,
                    batchName: this.formData.batchName,
                    returnTime: this.formData.returnTime
                }
                console.log(params);
                this.$api.paperLibrary.postBatchCrate(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.getAllBatch();
                            this.$message.success("创建成功！");
                            this.insertModel = false;
                        } else {
                            this.$message.warning("创建失败！");
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            async getSendingRuleList() {
                await this.$api.paperLibrary.getSendingRuleList()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.sendingRuleList = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            getAllBatch() {
                this.$api.paperLibrary.getAllBatch()
                    .then(res => {
                        if (res.data.code == 200) {
                            this.batchList = res.data.data;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护");
                    })
            },
            insertBatchHandle() {
                this.insertModel = true;
            },
            enterBatchHandle(id, name) {
                this.$store.dispatch('batch/changeBatch', name);
                this.$router.push({
                    name: 'paperLibrary',
                    params: {
                        id: id
                    }
                })
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>

<style>
    .batchTable .vxe-table--body-wrapper {
        height: calc(100vh - 188px);
    }
</style>