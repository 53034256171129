import { render, staticRenderFns } from "./BatchManage.vue?vue&type=template&id=b18d2aae&scoped=true&"
import script from "./BatchManage.vue?vue&type=script&lang=js&"
export * from "./BatchManage.vue?vue&type=script&lang=js&"
import style1 from "./BatchManage.vue?vue&type=style&index=1&id=b18d2aae&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b18d2aae",
  null
  
)

export default component.exports